.resend-button{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;  
    margin-top: 5px; 
    margin-bottom: 35px;  
}

.verify-label{
    text-align: center;
    margin: 10px;
}

.sent-label{
    color: #4d8f53;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
}