body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* position:fixed; */
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.spinner-border{
  margin: 10px;
  width: 5rem;
  height: 5rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade.alert.alert-danger.alert-dismissible{
  width: 500px;
  margin: auto;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.head_top_contains{
    width: 100%;
    height: auto;
    margin: 7px;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    flex-direction: column;
    background-color: rgb(66, 66, 199);
}
.Login_form_container{
    width: 500px;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height:600px; 
    padding-top: 140px;
}

.center-text{
    text-align: center;
    margin: 15px 0;
}

.center-link{
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
}
.center-link:hover{
    text-decoration:underline;
    color: #e30c0c;
    font-size: 17px;
}
.head_top_contains{
    width: 99%;
    height: 100px;
    margin: 4px;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    background-color: rgb(66, 66, 199);
}
.left_of_menu{
    flex: 3 1;
}
.menu{
    flex: 1 1;
    margin: 15px;
   text-align: right;
}
.button{
    margin: 10px;
}
.footer_contains{
    text-align: center;
    width: 99%;
    height: 100px;
    margin: 4px;
    background-color: rgb(66, 66, 199);
}
.resend-button{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;  
    margin-top: 5px; 
    margin-bottom: 35px;  
}

.verify-label{
    text-align: center;
    margin: 10px;
}

.sent-label{
    color: #4d8f53;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
}

.rent_listing_container{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height:600px; 
}

.property{
    /* background-color: aqua; */
    display: flex;
}
.property_info{
    flex: 1 1;
    /* background-color: blueviolet; */
}
.property_pictures{
    flex: 1 1;
    /* background-color: brown; */
    text-align: center;
}
.rest_of_the_info{
    /* background-color:chartreuse; */
}
.prop_input{
    margin: 4px;
}
